//header-sidemenu
$width-sidemenu: 285px;
$hidden-sidemenu: 51px;
$hight-header: 50px;

// Fonts
$font-family-kavalli: 'Inter';
$font-family-kavalli-semi-bold: 'open-sans-semibold';

// Colors
$primary-color: #1B79E0;
$primary-color-extra-light: #baa9de;
$primary-color-light: #3877ff;
$background-color: #f6f7f9;
$primary-text-color: #4b4b4b;
$secondary-on-color: #757575;
$secondary-color: #707070;
$background-on-color: #f8f8f8;
$background-input-search: #eaeaea;
$gray-color: #f2f2f2;
$border-color: #e3e3e3;
$readonly-bg: #eaecef;
$readonly-color: #ced4da;
$background-header: #f6f7f9;
$dark: #222222;
$dark-2: #161616;
$gray: #b6b6b6;
$gray-2: #808080;
$gray-medium: #a5a5a5;
$red: #de0d0d;
$dark-red: #aa2c2c;
$alert-red: #e15050;
$mid-red: #be7e85;
$border-dark: #c4c4c4;
$text-inputs-label: #393939;
$border-color-form: #e4e4e4;

$green: #55aa3c;
$medium-green: #318327;
$yellow: #fcde61;
$yellow-dark: #e0bc22;
$light-blue: #f0f5ff;
$gray-extra-light: #b5b5b5;

//alerts
$success-background: #d1e7dd;
$success-color: #0f5132;
$success-border: #badbcc;
$error-background: #f8d7da;
$error-color: #721c24;
$error-border: #f5c6cb;
$warn-background: #fff3cd;
$warn-color: #664d03;
$warn-border: #ffecb5;
// Text
$line-hight: 20px;
$line-hight-cto: 41px;

// Pressets
$max-width: 1200px;
$max-width-swiper-mobile: 414px;
$width-logo: 180px;
$hight-footer: 320px;
$call-to-action-height: 444px;
$safe-zone-height: 336px;

// Btn Default
$btn-on-color: #f6f7f9;


$border-radius-sm: 8px;

//font sizes
$size-48px: 3rem;
$size-40px: 2.5rem;
$size-32px: 2rem;
$size-28px: 1.75rem;
$size-25px: 1.563rem;
$size-24px: 1.5rem;
$size-22px: 1.375rem;
$size-23px: 1.438rem;
$size-20px: 1.25rem;
$size-16px: 1rem;
$size-15px: 0.938rem;
$size-14px: 0.875rem;
$size-13px: 0.813rem;
$size-12px: 0.75rem;
$size-10px: 0.625rem;
$size-9px: 0.563rem;
$size-8px: 0.5rem;

// font color
$color-neutral-24: #3d3d3d;
