.p-slider {
   &-range {
      background: $primary-color !important;
      border-radius: 6px;
   }
   &-handle {
      border: solid 2px $primary-color !important;
      &:hover {
         background: $primary-color !important;
      }
      &:focus {
         box-shadow: 0 0 0 0.08rem darken($color: $primary-color , $amount: 10%)!important;
      }
   }
}
