.sidemenu {
   li {
      padding: 10px 0;
      cursor: pointer;
      color: $dark;
      @include transition(0.2s);
      box-sizing: border-box;
      font: {
         size: 0.875rem;
         family: $font-family-kavalli;
      }
      &:hover,
      &.active {
         color: $primary-color-light !important;
      }

      &.active {
         font-weight: 600;
      }

      &:first-child {
         margin-top: 8px;
      }
   }
}
